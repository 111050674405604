<script setup lang="ts">
import type { genders } from '~/src/classes.ts';

const props = defineProps<{
    gender: typeof genders[number];
    concise?: boolean;
}>();

const iconName = computed((): string => {
    const iconNames = {
        masc: 'mars',
        fem: 'venus',
        neutr: 'neuter',
    };
    return iconNames[props.gender];
});
const longIdentifier = computed((): string => {
    const longIdentifiers = {
        masc: 'masculine',
        fem: 'feminine',
        neutr: 'neuter',
    };
    return longIdentifiers[props.gender];
});
</script>

<template>
    <Tooltip v-if="concise" :text="$t(`nouns.${longIdentifier}`)">
        <Icon :v="iconName" />
    </Tooltip>
    <div v-else class="text-nowrap">
        <Icon :v="iconName" />
        <span><T>nouns.{{ longIdentifier }}</T></span>
    </div>
</template>
