<script setup lang="ts">
import { genders, gendersWithNumerus } from '~/src/classes.ts';
import type { MinimalNoun } from '~/src/classes.ts';
import { nounTemplates } from '~/src/data.ts';

const props = withDefaults(defineProps<{
    templateBase?: string;
    filter?: string;
}>(), {
    templateBase: '',
    filter: '',
});

const templates = computed((): (MinimalNoun & { id: string })[] => {
    return nounTemplates.filter((template) => {
        for (const field of gendersWithNumerus) {
            for (const value of template[field]) {
                if (value.toLowerCase().includes(props.filter)) {
                    return true;
                }
            }
        }
    }).map((template, i) => {
        return {
            id: `template-${i}`,
            ...template.fill(props.templateBase || '-'),
        };
    });
});
</script>

<template>
    <Table :data="templates" :columns="3" fixed>
        <template #header>
            <th v-for="gender in genders" :key="gender" class="text-nowrap">
                <NounsGenderLabel :gender="gender" />
            </th>
            <th></th>
        </template>
        <template #row="{ el: template }">
            <td v-for="gender in genders" :key="gender">
                <NounsItem :noun="template" :gender="gender" />
            </td>
            <th>
                <ul class="list-unstyled list-btn-concise">
                    <slot name="buttons" :template="template"></slot>
                </ul>
            </th>
        </template>

        <template #empty>
            <Icon v="search" />
            <T>nouns.empty</T>
        </template>
    </Table>
</template>
